import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Features from "../components/Features";
import Closer from "../components/Closer.js"

// eslint-disable-next-line
export const IndustryPageTemplate = ({
  image,
  heading,
  description,
  intro,
  fullImage,
}) => {

  return (
    <div className="content">
      <section className="section section--gradient" style={{
        backgroundColor: "#FF6633"
      }}>
        <div className="container">
          <div className="columns">
              <div className="column"></div>
                <div className="column is-half has-text-centered">
                  <h2 className="has-text-weight-semibold is-size-2" style={{
                  color: "#ffffff",
                }}>
                    {heading}
                  </h2>
                  <p style={{
                  color: "black",
                }}>{description}</p>
                </div>
              <div className="column"></div>
          </div>
        </div>
      </section>
     
      <section className="section" style={{
            backgroundImage: "url(/img/topo.svg)",
            backgroundSize: "100%",
            backgroundColor: "#000C29",
            overflow: "visible",
            backgroundRepeat: "no-repeat",
            paddingTop: "2.5em",
            paddingBottom: "5em",
          }}>
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div>
                <Features gridItems={intro.blurbs}/> 
              </div>
            </div>
          </div>
        </div>
      </section>
      <Closer />
    </div>
  );
};

IndustryPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  clients: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image4: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image5: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image6: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  partners: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image4: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image5: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image6: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  testimonials: PropTypes.array,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
};

const IndustryPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndustryPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        clients={frontmatter.clients}
        partners={frontmatter.partners}
        testimonials={frontmatter.testimonials}
        fullImage={frontmatter.full_image}
        pricing={frontmatter.pricing}
      />
    </Layout>
  );
};

IndustryPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndustryPage;

export const industryPageQuery = graphql`
  query IndustryPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 500, quality: 100, layout: CONSTRAINED)
              }
            }
            header
            text 
            url
          }
        }
      }
    }
  }
`;
